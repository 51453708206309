<template>
  <div>
    <!-- REFETCH BUTTON -->
    <v-btn
      color="primary"
      class="mb-3 pl-3 pr-4"
      @click="refetchDonteReports"
    >
      <v-icon class="mr-2">
        {{ icons.mdiReload }}
      </v-icon>
      Refetch
    </v-btn>

    <!-- REPORTS -->
    <v-card class="my-4">
      <v-simple-table>
        <template #default>
          <thead class="position-relative">
            <tr>
              <th
                scope="col"
                class="text-center"
                style="width: 10%;"
              >
                ID
              </th>
              <th
                scope="col"
                class="text-left"
                style="width: 60%;"
              >
                Created at
              </th>
              <th
                scope="col"
                class="text-center"
              >
                Status
              </th>
              <th
                scope="col"
                class="text-center"
                style="width: 5%;"
              >
                View
              </th>
              <th
                scope="col"
                class="text-center"
                style="width: 5%;"
              >
                Delete
              </th>
            </tr>

            <v-progress-linear
              bottom
              absolute
              indeterminate
              :active="$apollo.queries.donteReports.loading"
            ></v-progress-linear>
          </thead>

          <tbody class="position-relative">
            <template v-if="donteReports && donteReports.data">
              <tr
                v-for="report in donteReports.data"
                :key="report.id"
              >
                <td class="text-center">
                  {{ report.id }}
                </td>
                <td class="text-left">
                  {{ formatTime(report.created_at) }}
                </td>
                <td class="text-center">
                  <v-chip
                    :class="chipColorClass(report)"
                  >
                    {{ report.status }}
                  </v-chip>
                </td>
                <td class="text-center">
                  <v-btn
                    color="secondary"
                    plain
                    :disabled="report.status != 'completed'"
                    @click="viewDonteReport(report.id)"
                  >
                    <v-icon>{{ icons.mdiTableEye }}</v-icon>
                  </v-btn>
                </td>
                <td class="text-center">
                  <v-btn
                    color="error"
                    plain
                    :disabled="report.status === 'generating' || $apollo.queries.donteReports.loading"
                    @click="openDeleteDialog(report.id)"
                  >
                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!-- PAGINATION -->
    <v-pagination
      v-model="currentPage"
      :length="lastPage"
      circle
    ></v-pagination>

    <!-- DELETE DIALOG -->
    <v-dialog
      v-model="isDialogVisible"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Delete report with ID {{ reportToDelete }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="isDialogVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            :disabled="isDeleting"
            @click="deleteReport"
          >
            <v-progress-circular
              v-if="isDeleting"
              indeterminate
              :size="20"
              color="secondary"
              class="mr-2"
            ></v-progress-circular>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */

import gql from 'graphql-tag'

import { mdiTableEye, mdiDelete, mdiReload } from '@mdi/js'

export default {
  apollo: {
    donteReports: {
      query: gql`
        query ($page: Int) {
          donteReports(page: $page) {
            data {
              id
              status
              created_at
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      variables() {
        return {
          page: this.currentPage,
        }
      },
      result(res) {
        if (res.data.donteReports.data.length === 0 && this.currentPage !== 1) {
          this.currentPage -= 1
        } else {
          this.currentPage = res.data.donteReports.paginatorInfo.currentPage
          this.lastPage = res.data.donteReports.paginatorInfo.lastPage
        }
      },
      pollInterval: 10000,
      fetchPolicy: 'network-only',
    },
  },

  data() {
    return {
      isDialogVisible: false,
      reportToDelete: null,
      isDeleting: false,

      currentPage: 1,
      lastPage: undefined,

      icons: {
        mdiTableEye,
        mdiDelete,
        mdiReload,
      },
    }
  },

  methods: {
    formatTime(createdAt) {
      let createdAtDate = new Date(createdAt)
      return createdAtDate.toLocaleString('en-UK', { hour12: false })
    },

    refetchDonteReports() {
      console.log(this.$apollo.queries.donteReports)
      this.$apollo.queries.donteReports.refetch()
    },

    viewDonteReport(id) {
      this.$router.push('/donte-reports/report/' + id)
    },

    openDeleteDialog(id) {
      this.reportToDelete = id
      this.isDialogVisible = true
    },

    deleteReport() {
      this.isDeleting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($reportId: ID!) {
              deleteDonteReport(id: $reportId) {
                id
              }
            }
          `,
          variables: {
            reportId: this.reportToDelete,
          },
        })
        .then(data => {
          this.isDeleting = false
          this.isDialogVisible = false
          this.$apollo.queries.donteReports.refetch()
        })
    },

    chipColorClass(report) {
      let colorClass = ''

      if (report.status === 'completed') colorClass = 'v-chip-light-bg success--text'
      else if (report.status === 'failed') colorClass = 'v-chip-light-bg error--text'
      else if (report.status === 'generating') colorClass = 'v-chip-light-bg warning--text'

      return colorClass
    },
  },
}
</script>

<style>
.v-application .warning--text {
  color: #ffd700 !important;
}

.v-application .success--text {
  color: #b7bf10 !important;
}
</style>
